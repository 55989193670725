* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background: linear-gradient(90deg, #F68080 0%, #F9B16E 85.17%);
    font-family: "Roboto Slab", serif; /* Основен шрифт */
    margin: 0;
    /*display: flex;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    height: 100vh;
}

.app-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center; /* Центриране на контейнера по ширина */
    justify-content: flex-start; /* Запазва позицията на контейнера отгоре */
    min-height: 100vh; /* Гарантира, че футърът ще остане най-отдолу */
}


.container-size {
    flex: 1; /* Контейнерът заема оставащото място */
    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
    width: 1170px;
    height: 685px;
    flex-shrink: 0; /* Гарантира, че контейнерът няма да се свие */
    border-radius: 36px;
    background: #F8F8F8;
    display: flex; /* Основна настройка на flexbox за подредба вътре в контейнера */
    flex-direction: column; /* Вертикално подреждане на децата */
    padding: 32px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin-bottom: 16px; /* Разстояние между контейнера и футъра */
}

.header {
    display: flex;
    align-items: flex-start; /* Подравняване по горната част */
    justify-content: space-between; /* Логото вляво, социалните икони вдясно */
    padding: 0 5px;
    margin-bottom: 32px;
}

.logo {
    width: 258px;
    height: auto;
    margin-top: 10px; /* Добавяне на разстояние за вертикално изравняване */
}

.social {
    display: flex;
    align-items: center;
    gap: 26px;
}

.social img {
    width: 24px;
    height: 24px;
    cursor: pointer;
    fill: #000; /* Основен цвят на иконата */
    transition: transform 0.3s ease, fill 0.3s ease;
}

.social-link {
    color: blue;
    text-decoration: none;
}

.social img:hover {
    transform: scale(1.1);
    fill: #4EE4C1;
}

.content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; /* Подравняване по горната част */
    flex: 1;
    gap: 20px; /* Разстояние между текста и илюстрацията */
}

.announcement {
    max-width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Подравняване по горната част */
    text-align: left;
    margin-top: 10px; /* За допълнително изравняване, ако е нужно */
}

.announcement h2 {
    font-size: 24px;
    margin-bottom: 12px;
    color: #9D9D9D;
    font-family: "Roboto Slab", serif;
}

.announcement h1 {
    font-size: 64px; /* Запазва пропорциите на заглавието */
    line-height: 85px; /* Балансира височината на редовете */
    margin-bottom: 24px;
    color: #333;
    font-family: "Roboto Slab", serif;
}

.subscribe-form {
    display: flex;
    gap: 10px;
}

.subscribe-form input {
    flex: 1;
    padding: 12px 16px;
    border: 1px solid #CCC;
    border-radius: 14px;
    font-size: 16px;
    font-family: "Roboto Slab", serif;
}

.subscribe-form button {
    padding: 12px 24px;
    background: #f08382;
    /*background: linear-gradient(90deg, #155C6B 0%, #4EE4C1 85%);*/
    color: #FFF;
    border: none;
    border-radius: 14px;
    font-size: 16px;
    font-weight: 600;
    font-family: "Roboto Slab", serif;
    cursor: pointer;
    transition: background 0.3s ease;
}

.subscribe-form button:hover {
    background: #f5b174;
    /*background: linear-gradient(90deg, #0F484F 0%, #3CC0A6 85%);*/
}

.illustration {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 35%;
}

.illustration img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
}


@media (min-width: 2560px) {
    .container {
        width: 80%;
        max-width: 1800px; /* Ограничаваме максималната ширина */
        padding: 120px;
        border-radius: 50px;
    }

    .announcement h1 {
        font-size: 96px; /* Увеличаваме размера на заглавието */
        line-height: 110px;

    }

    .announcement h2 {
        font-size: 32px; /* Увеличаваме подзаглавието */
    }

    .subscribe-form input,
    .subscribe-form button {
        font-size: 20px; /* По-голям текст във формуляра */
        padding: 16px;
    }

    .logo {
        width: 350px; /* По-голямо лого */
    }

    .social img {
        width: 32px;
        height: 32px;
    }

}

@media (min-width: 1920px) {
    .container {
        width: 90%; /* Увеличаване на ширината за големи екрани */
        max-width: 1600px; /* Максимална ширина за центрирано съдържание */
        height: auto;
        padding: 90px; /* Увеличаване на вътрешните отстояния */
        border-radius: 40px; /* По-големи заоблени ъгли */
    }

    .announcement h1 {
        font-size: 80px; /* Увеличаване на размера на заглавията */
        line-height: 100px;
    }

    .announcement h2 {
        font-size: 28px; /* Увеличаване на подзаглавията */
    }

    .subscribe-form input,
    .subscribe-form button {
        font-size: 18px; /* По-голям текст във формуляра */
    }

    .logo {
        width: 300px; /* Увеличаване на логото */
    }

    .social img {
        width: 28px; /* По-големи икони за социални мрежи */
        height: 28px;
    }

}


/* --- Media Queries --- */
@media (max-width: 1440px) {
    .container {
        width: 70%; /* Намаляване на ширината за лаптопи */
        height: auto;
        padding: 24px;
        border-radius: 30px; /* Малко по-малки закръгления */
    }

    .announcement h1 {
        font-size: 48px; /* Умерено намаляване на шрифта */
        line-height: 65px;
    }

    .announcement h2 {
        font-size: 20px;
    }

    .subscribe-form input,
    .subscribe-form button {
        font-size: 14px; /* Намаляване на шрифта във формата */
    }

    .logo {
        width: 250px; /* Намаляване на логото за пропорционалност */
    }

    .social img {
        width: 20px; /* По-малки икони за социални мрежи */
        height: 20px;
    }
}


@media (max-width: 1366px) {
    .container {
        width: 80%;
        height: auto;
        padding: 2.5rem 2.5rem;
        margin-top: 1rem;
    }

    .announcement h1 {
        font-size: 3.75rem;
        line-height: 4.6875rem;
    }

    .announcement h2 {
        font-size: 1.25rem;
    }

    .subscribe-form input,
    .subscribe-form button {
        font-size: 0.875rem;
    }

    .logo {
        width: 15.625rem;
    }

    .social img {
        width: 1.5rem;
        height: 1.5rem;
    }
}

/* За таблети (768px - 1024px) */
@media (max-width: 1024px) {
    .container {
        width: 90%;
        height: auto;
        padding: 24px;
    }

    .announcement h1 {
        font-size: 48px;
        line-height: 65px;
    }

    .announcement h2 {
        font-size: 20px;
    }

    .subscribe-form input,
    .subscribe-form button {
        font-size: 14px;
    }

    .logo {
        width: 200px;
    }
}

/* За телефони (до 768px) */
@media (max-width: 768px) {
    body {
        background: #FFF; /* Бял фон */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        height: auto;
        padding: 0;
        margin: 0;
    }

    .container {
        width: 100%; /* Контейнерът заема цялата ширина */
        height: auto; /* Автоматична височина */
        padding: 16px;
        border-radius: 0; /* Премахваме заоблените ъгли */
        box-shadow: none; /* Премахваме сянката */
        background: #FFF; /* Убедяваме се, че контейнерът също има бял фон */
    }

    .header {
        flex-direction: column;
        align-items: center;
        gap: 12px; /* Разстояние между логото и социалните мрежи */
        margin-bottom: 16px; /* Разстояние под хедъра */
    }

    .logo {
        width: 280px; /* Намалено лого */
        margin-bottom: 10px;
    }

    .social img {
        width: 28px; /* Леко по-големи икони за лесна интеракция */
        height: 28px;
        cursor: pointer;
    }

    .content {
        flex-direction: column;
        align-items: center; /* Центриране на съдържанието */
        text-align: center; /* Центриране на текста */
        gap: 20px; /* Разстояние между елементите */
    }

    .announcement {
        max-width: 100%; /* Позволяваме на текста да заема цялата ширина */
        margin-bottom: 20px; /* Разстояние под секцията */
    }

    .announcement h1 {
        font-size: 28px; /* Намален размер на заглавието */
        line-height: 40px;
        color: #333; /* Леко по-мек цвят на текста */
    }

    .announcement h2 {
        font-size: 16px;
        color: #666; /* По-мек цвят за подзаглавието */
    }

    .subscribe-form {
        flex-direction: column; /* Вертикално подреждане */
        gap: 12px; /* Разстояние между полето и бутона */
        width: 100%;
    }

    .subscribe-form input {
        width: 100%;
        padding: 12px;
        border: 1px solid #DDD;
        border-radius: 8px;
        font-size: 14px;
    }

    .subscribe-form button {
        width: 100%;
        padding: 14px;
        font-size: 16px;
        font-weight: bold;
        color: white;
        background: #f08382;
        /*background: linear-gradient(90deg, #4EE4C1 0%, #155C6B 100%);*/
        border: none;
        border-radius: 8px;
        cursor: pointer;
        transition: background 0.3s ease;
    }

    .subscribe-form button:hover {
        background: #f5b174;
        /*background: linear-gradient(90deg, #0F484F 0%, #3CC0A6 100%);*/
    }

    .illustration {
        max-width: 80%;
        margin: 0 auto; /* Центрираме илюстрацията */
    }

}
